var render = function() {
  var _vm = this
  var _h = _vm.$createElement
  var _c = _vm._self._c || _h
  return _c(
    "a-modal",
    {
      attrs: { visible: _vm.visible, footer: null },
      on: { cancel: _vm.handleCancelModal }
    },
    [
      _c(
        "span",
        {
          staticClass: "title text-black font-semi-bold",
          attrs: { slot: "title" },
          slot: "title"
        },
        [
          _vm._v(
            _vm._s(
              _vm.$tc("Select destination page", _vm.actionType === "copy")
            )
          )
        ]
      ),
      _vm.isProjectWithPages
        ? _c("ProjectPageSelect", {
            staticClass: "mb-sm",
            staticStyle: { width: "100%" },
            attrs: {
              value: _vm.page.id,
              data: _vm.filterProject,
              pageId: +_vm.$route.params.pageId
            },
            on: { select: _vm.onSelect }
          })
        : _c(
            "a-radio-group",
            {
              model: {
                value: _vm.page,
                callback: function($$v) {
                  _vm.page = $$v
                },
                expression: "page"
              }
            },
            _vm._l(_vm.filterPage, function(pageItem) {
              return _c(
                "a-radio",
                {
                  key: pageItem.id,
                  style: _vm.radioStyle,
                  attrs: { value: pageItem }
                },
                [
                  _vm._v(
                    " " + _vm._s(_vm._f("truncate")(pageItem.name, 30)) + " "
                  )
                ]
              )
            }),
            1
          ),
      _c(
        "div",
        { staticClass: "content-end" },
        [
          _c(
            "a-button",
            { staticClass: "mr-sm", on: { click: _vm.handleCancelModal } },
            [_vm._v(" " + _vm._s(_vm.$t("Cancel")) + " ")]
          ),
          _c(
            "a-button",
            {
              attrs: {
                type: "primary",
                disabled: !_vm.page,
                loading: _vm.loading
              },
              on: { click: _vm.handleSubmit }
            },
            [_vm._v(" OK ")]
          )
        ],
        1
      )
    ],
    1
  )
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }