//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//

export default {
  components: {
    ProjectPageSelect: () => import('@/components/tools/ProjectPageSelect.vue')
  },
  props: {
    visible: { type: Boolean, required: true },
    actionType: { type: String, default: '' },
    pages: { type: Array, default: () => [] },
    projects: { type: Array, default: () => [] }
  },
  data() {
    return {
      radioStyle: {
        display: 'block',
        height: '30px',
        lineHeight: '30px'
      },
      page: {},
      loading: false
    };
  },
  computed: {
    filterPage() {
      return this.pages.filter(item => item.id != this.currentPageId);
    },
    filterProject() {
      // rename projects.projects to projects.children
      const result = this.projects.map(project => {
        project.id = 'project' + project.id;
        project.children = project.projects.filter(
          page => page.id != this.currentPageId && page.template !== 'PORTAL'
        );
        return project;
      });
      return result;
    },
    currentPageId() {
      return this.$route.params?.pageId;
    },
    isProjectWithPages() {
      return this.projects.length > 0;
    }
  },
  methods: {
    handleSubmit() {
      this.loading = true;
      new Promise((resolve, reject) => {
        let vm = this;
        this.$confirm({
          title: vm.$tc(
            'Do you want to jump to the copy(move) destination?',
            vm.actionType == 'copy'
          ),
          // content: 'When clicked the OK button, this dialog will be closed after 1 second',
          async onOk() {
            vm.$emit(vm.actionType, { selectedPageId: vm.page.id, resolve, reject });
            setTimeout(() => {
              vm.$router.push(`/projects/${vm.page.campaign_id}/pages/${vm.page.id}/codes`);
            }, 1000);
          },
          onCancel() {
            vm.$emit(vm.actionType, { selectedPageId: vm.page.id, resolve, reject });
          },
          cancelText: vm.$t('No'),
          okText: vm.$t('Yes')
        });
        this.loading = false;
      });
    },
    onSelect(page) {
      this.page = page;
    },
    handleCancelModal() {
      this.$emit('cancel');
    }
  }
};
